export const updateShopifyCms = async (cmsBarHandle = '', cmsBardata = '') => {
    try {
        const endpoint = `${process.env.REACT_APP_FS_SERVER_BASE_URL}/v0/api/shopifyCms/metaobject/${cmsBarHandle}`
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "data": cmsBardata || []
        });
        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        const result = await fetch(endpoint, requestOptions).then(async (response) => {
            const responseRes = JSON.parse(await response?.text());
            const message = responseRes?.details?.[0]?.message || responseRes
            return {
                status: response?.status, message: message
            }
        })
     
        return result;
    }
    catch (error) {
       
        return { status: 500, message: 'failed to fetch a respnse' }
    }

}