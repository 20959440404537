import React from "react";
import ProductsRender from "./ProductsRender";

const isSafeElement = (elementType) => {
  const safeElements = [
    "div",
    "p",
    "span",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "li",
    "ol",
    "a",
    "img",
    "b",
    "i",
    "strong",
    "em",
    "br",
    "hr",
    "blockquote",
    "code",
    "pre",
    "table",
    "thead",
    "tbody",
    "tr",
    "td",
    "th",
    "style",
  ];

  // Disallow <script> elements
  if (elementType === "script") {
    return false;
  }

  // Allow only safe HTML elements
  return safeElements.includes(elementType);
};

const isSafeAttribute = ({ attribute, type, prop }) => {
  if (type === "style" && attribute === "dangerouslySetInnerHTML") {
    // check if prop is safe
    if (
      prop.__html.includes("script") ||
      prop.__html.includes("eval") ||
      prop.__html.includes("javascript") ||
      prop.__html.includes("expression")
    ) {
      return false;
    } else return true;
  }
  if (typeof prop === "string") {
    if (
      prop.includes("script") ||
      prop.includes("eval") ||
      prop.includes("javascript") ||
      prop.includes("expression")
    ) {
      return false;
    }
  }
  const safeAttributes = [
    "children",
    "alt",
    "class",
    "className",
    "contenteditable",
    "data-*",
    "dir",
    "draggable",
    "height",
    "hidden",
    "id",
    "lang",
    "placeholder",
    "readonly",
    "spellcheck",
    "src",
    "style", // Be cautious with inline styles, but they cannot directly execute scripts.
    "tabindex",
    "title",
    "translate",
    "value",
    "width",
    "href", // Cannot execute scripts directly, but `javascript:` URLs can be dangerous.
    "type",
    "accept",
    "autocomplete",
    "checked",
    "dirname",
    "disabled",
    "form",
    "formaction",
    "formenctype",
    "formmethod",
    "formnovalidate",
    "formtarget",
    "list",
    "max",
    "maxlength",
    "min",
    "multiple",
    "name",
    "pattern",
    "size",
    "step",
    "target",
    "async", // Although related to scripts, it doesn't contain executable code.
    "defer", // Same as async.
    "integrity", // Same as async and defer.
    "nonce", // Used for security, does not execute code.
    "preload",
    "controls",
    "autoplay",
    "loop",
    "muted",
    "crossorigin",
    "download",
    "rel",
    "shape",
    "coords",
  ];
  return safeAttributes.includes(attribute);
};

const DynamicComponent = ({ json }) => {
  const isValidJSON = (str) => {
    if (typeof str === "string") {
      try {
        const data = JSON.parse(str);
        return <ProductsRender data={data} />;
      } catch (e) {
        return str;
      }
    }
    return str;
  };

  if (typeof json === "string") {
    return json;
  }

  const { type, props } = json;

  // remove unsafe props
  if (props) {
    Object.keys(props).forEach((key) => {
      if (!isSafeAttribute({ attribute: key, type, prop: props[key] })) {
        delete props[key];
      }
    });
  }

  if (!isSafeElement(type)) {
    return null;
  }

  return React.createElement(
    type,
    { ...props, key: Math.random() },
    Array.isArray(props.children) ? (
      props.children.map((child, index) => (
        <DynamicComponent key={index} json={child} />
      ))
    ) : typeof props.children === "object" && props.children !== null ? (
      <DynamicComponent json={props.children} />
    ) : (
      isValidJSON(props.children)
    )
  );
};

export default DynamicComponent;
