import React from 'react';
// third-party
import { configureStore } from '@reduxjs/toolkit';
// project import
import reducers from './reducers/index';
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
export const store = configureStore({
  reducer: reducers
});

export default store;
