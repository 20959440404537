import React from 'react';

// assets
import { DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const shopifyCms = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    // {
    //   id: 'announcementBar',
    //   title: 'AnnouncementBar',
    //   type: 'item',
    //   url: '/shopifyCms/announcementBar',
    //   breadcrumbs: false
    // },
    {
      id: 'navBar',
      title: 'navBar',
      type: 'item',
      url: '/shopifyCms/navBar',
      breadcrumbs: false
    },
    {
      id: 'mainBanner',
      title: 'MainBanner',
      type: 'item',
      url: '/shopifyCms/mainBanner',
      breadcrumbs: false
    },
    {
      id: 'collectionArray',
      title: 'CollectionArray',
      type: 'item',
      url: '/shopifyCms/collectionArray',
      breadcrumbs: false
    },
    {
      id: 'collectionGrid',
      title: 'CollectionGrid',
      type: 'item',
      url: '/shopifyCms/collectionGrid',
      breadcrumbs: false
    },

    // {
    //   id: 'featuredCollection',
    //   title: 'FeaturedCollection',
    //   type: 'item',
    //   url: '/shopifyCms/featuredCollection',
    //   breadcrumbs: false
    // },
    // {
    //   id: 'brandingBanner',
    //   title: 'BrandingBanner',
    //   type: 'item',
    //   url: '/shopifyCms/brandingBanner',
    //   breadcrumbs: false
    // },
    // {
    //   id: 'testimonialsBar',
    //   title: 'TestimonialsBar',
    //   type: 'item',
    //   url: '/shopifyCms/testimonialsBar',
    //   breadcrumbs: false
    // },


    // {
    //   id: 'brandingSection',
    //   title: 'BrandingSection',
    //   type: 'item',
    //   url: '/shopifyCms/brandingSection',
    //   breadcrumbs: false
    // },
    // {
    //   id: 'blogSection',
    //   title: 'BlogSection',
    //   type: 'item',
    //   url: '/shopifyCms/blogSection',
    //   breadcrumbs: false
    // },
    // {
    //   id: 'pdp',
    //   title: 'Pdp',
    //   type: 'item',
    //   url: '/shopifyCms/pdp',
    //   breadcrumbs: false
    // },
  ]
};

export default shopifyCms;
