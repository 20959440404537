import React from "react";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";

const Preview = ({ children, open, setOpen }) => {
  return (
    <Box sx={{ display: open ? "block" : "none" }}>
      <AppBar
        position="sticky"
        component="nav"
        sx={{
          bgcolor: "White",
          color: "Black",
          boxShadow: "none",
        }}
      >
        <Button onClick={() => setOpen(!open)} sx={{ position: "absolute" }} variant="contained">Close</Button>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          sx={{
            minHeight: { xs: "50px", md: "65px" },
          }}
        >
          <p>NAV BAR</p>
        </Box>
      </AppBar>
      <Box>{children}</Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: "White",
          color: "Black",
          boxShadow: "none",
          minHeight: "200px",
          borderTop: "1px solid #f0f0f0",
        }}
      >
        <span>FOOTER</span>
      </Box>
    </Box>
  );
};

export default Preview;
