import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = { announcementBarList: [], blogSection: [], brandingBanner: [], brandingSection: [], collectionArray: [], collectionGrid: [], featuredCollection: [], mainBanner: [], navBar: [], pdp: [], subBar: [], testimonialsBar: [] };
// ==============================|| SLICE - MENU ||============================== //
const shopifyCmsBarList = createSlice({
    name: 'shopifyCmsBarList',
    initialState,
    reducers: {
        setAnnouncementList(state, action) {
            state.announcementBarList = action.payload.announcementBarList
        },
        setBlogSection(state, action) {
            state.blogSection = action.payload.blogSection
        },
        setBrandingBanner(state, action) {
            state.brandingBanner = action.payload.brandingBanner
        }
        ,
        setBrandingSection(state, action) {
            state.brandingSection = action.payload.brandingSection
        },
        setCollectionArray(state, action) {
            state.collectionArray = action.payload.collectionArray
        },

        setCollectionGrid(state, action) {
            state.collectionGrid = action.payload.collectionGrid
        },
        setFeaturedCollection(state, action) {
            state.featuredCollection = action.payload.featuredCollection
        },
        setMainBanner(state, action) {
            state.mainBanner = action.payload.mainBanner
        },
        setNavBar(state, action) {
            state.navBar = action.payload.navBar
        },
        setPdp(state, action) {
            state.pdp = action.payload.pdp
        },
        setSubBar(state, action) {
            state.subBar = action.payload.subBar
        },
        setTestimonialsBar(state, action) {
            state.testimonialsBar = action.payload.testimonialsBar
        }
    }
});
export default shopifyCmsBarList.reducer;
export const { setAnnouncementList, setBlogSection, setBrandingBanner, setBrandingSection, setCollectionArray, setCollectionGrid,
    setFeaturedCollection, setMainBanner, setNavBar, setPdp, setSubBar, setTestimonialsBar } = shopifyCmsBarList.actions;