"use client";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
// import ProductTag from "../ProductTag";
import Typography from "@mui/material/Typography";
// import WishlistButton from "../WishlistButton";

const ProductItem = ({
  data,
  isSearchPage,
  isWishlist,
  collectionId,
  collectionName,
  isSimilarProducts,
  nextImage,
}) => {
  const imageContainer = useRef(null);
  const [isHovering, setIsHovered] = useState(false);
  const [tags, setTags] = useState([]);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [discountedTag, setDiscountedTag] = useState(null);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const id = data?.id?.split("/").pop() || data?.product_id;
  const url = `/products/${data?.handle}`;

  useEffect(() => {
    if (data.tags && data.tags?.length) {
      if (typeof data.tags === "string") data.tags = data.tags.split(",");
      const tagsArray = [];
      data.tags.forEach((item) => {
        if (item.includes("__tag") && !item.toLowerCase().includes("save")) {
          const removedTag = item.replace("__tags", "");
          tagsArray.push(removedTag);
        }
      });
      setTags(tagsArray);
    }
    if (data?.compareAtPriceRange?.minVariantPrice?.amount) {
      if (
        Number(data?.compareAtPriceRange?.minVariantPrice?.amount) >
        Number(data?.priceRange?.minVariantPrice?.amount)
      ) {
        setDiscountedPrice(data?.compareAtPriceRange?.minVariantPrice?.amount);
        const discountPercentage = Math.round(
          (1 -
            Number(data?.priceRange?.minVariantPrice?.amount) /
              Number(data?.compareAtPriceRange?.minVariantPrice?.amount)) *
            100
        );
        setDiscountedTag(`${discountPercentage}% OFF`);
      }
    }
    if (isSearchPage) {
      if (data?.compare_at_price > data?.price) {
        setDiscountedPrice(data?.compare_at_price);
        const discountPercentage = Math.round(
          (1 - Number(data?.price) / Number(data?.compare_at_price)) * 100
        );
        setDiscountedTag(`${discountPercentage}% OFF`);
      }
    }
  }, [data]);

  const isValidURL = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };

  const productAvailability = isSimilarProducts
    ? true
    : isSearchPage
    ? data?.available_variants?.length > 0
      ? true
      : false
    : !data?.availableForSale && collectionName
    ? false
    : true;

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{ position: "relative", height: "calc(100% - 38px)", mb: 0.5 }}
      >
        {/* {tags[0] ? <ProductTag key={tags[0]} tag={tags[0]} top="0px" /> : null}
        <WishlistButton
          iconButtonSx={{ position: "absolute", right: "5px", zIndex: "2" }}
          id={id}
          productData={data}
          isWishlist={isWishlist}
          iconSx={{
            width: { xs: "18px", md: "20px" },
            height: { xs: "18px", md: "20px" },
          }}
          page={"plp"}
          collectionId={collectionId}
          collectionName={collectionName}
        /> */}
        <a href={`${url}`}>
          <Box ref={imageContainer} sx={{ width: "100%" }}>
            {!productAvailability ? (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.45)",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "white.main",
                }}
              >
                Sold Out
              </Box>
            ) : (
              <></>
            )}
            {isSearchPage ? (
              <div>
                <img
                  src={isValidURL(data?.image_url) ? data?.image_url : ""}
                  alt="product-image"
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{ width: "100%", height: "auto" }}
                  placeholder="blur"
                  blurDataURL="/logo.svg"
                />
              </div>
            ) : isHovering ? (
              <img
                src={
                  data?.images?.nodes?.[1]?.transformedSrc ||
                  data?.images?.nodes?.[1]?.url
                }
                alt="product-image"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: "100%", height: "auto" }}
                placeholder="blur"
                blurDataURL="/logo.svg"
              />
            ) : (
              <img
                src={
                  data?.images?.nodes?.[0]?.transformedSrc ||
                  data?.images?.nodes[0]?.url
                }
                alt="product-image"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: "100%", height: "auto" }}
                placeholder="blur"
                blurDataURL="/logo.svg"
              />
            )}
          </Box>
        </a>
      </Box>
      <Box>
        <a href={`${url}`}>
          <Typography
            variant="p"
            component="p"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              fontSize: { xs: "14px", sm: "14px" },
            }}
          >
            {data.title}
          </Typography>
          <Typography
            variant="span"
            color="primary"
            sx={{
              fontWeight: "500",
              fontSize: { xs: "14px", sm: "14px" },
            }}
          >
            ₹{" "}
            {data?.priceRange?.minVariantPrice?.amount
              ? Number(
                  data?.priceRange?.minVariantPrice?.amount
                ).toLocaleString("en-IN")
              : Number(data?.price).toLocaleString("en-IN")}
          </Typography>
          {discountedPrice && (
            <Typography
              variant="span"
              sx={{
                textDecoration: "line-through",
                color: "text.secondary",
                fontSize: { xs: "12px", sm: "14px" },
                ml: 1,
              }}
            >
              ₹ {Number(discountedPrice).toLocaleString("en-IN")}
            </Typography>
          )}
          {discountedTag && (
            <Typography
              variant="span"
              sx={{
                color: "#a10e2c",
                fontWeight: "500",
                fontSize: { xs: "12px", sm: "14px" },
                ml: 1,
              }}
            >
              {discountedTag}
            </Typography>
          )}
        </a>
      </Box>
    </Box>
  );
};

export default ProductItem;
