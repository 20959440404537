import React from 'react';

// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined
} from '@ant-design/icons';

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Utilities',
  type: 'group',
  children: [
    {
      id: 'util-bulkads',
      title: 'Bulk Upload Ads',
      type: 'item',
      url: '/cam-manager/ads',
    },
    {
      id: 'util-bulkadsets',
      title: 'Bulk Upload Adsets',
      type: 'item',
      url: '/cam-manager/adsets',
    },
    {
      id: 'util-switchStatusCampaignAndAdsetCsvUpload',
      title: 'Bulk Upload CampaignAndAdset',
      type: 'item',
      url: '/cam-manager/SwitchStatusCampaignAndAdsetCsvUpload',
    },
    
    {
      id: 'util-CommerceManagerCatalogueFeed',
      title: 'Bulk Upload CatalogueFeed',
      type: 'item',
      url: '/cam-manager/CommerceManagerCatalogueFeed',
    },
  ]
};

export default utilities;
