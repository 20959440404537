import React from 'react';

// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // To show which component should be visible
  const visibility = true;
  return (
    <>
      {matchesXs && !visibility && <Search />}
      {!matchesXs && visibility && <Box sx={{ width: '100%', ml: 1 }} />}

      {matchesXs && !visibility && <Notification />}
      {!matchesXs && visibility && <Profile />}
      {matchesXs && visibility && <MobileSection />}
    </>
  );
};

export default HeaderContent;
