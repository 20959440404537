import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Navigation from './Drawer/DrawerContent/Navigation/index';
import Breadcrumbs from '../../components/@extended/Breadcrumbs';

// types
import { openDrawer } from '../../store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const ShopifyLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const cookies = new Cookies();

  const navigate = useNavigate();

  const isloggedin = async () => {
    let accessToken = await cookies.get('jwt');
    let refreshToken = await cookies.get('refresh');
    if (accessToken == undefined && refreshToken == undefined) {
      navigate('/');
    }
    else if (!accessToken) {
      await fetch(`${process.env.REACT_APP_FS_SERVER_BASE_URL}/v0/api/auth/refreshtoken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_FS_SERVER_BASE_URL
        },
        body: JSON.stringify({ refreshToken: refreshToken }),
      })
        .then(async (response) => {
          const json = await response.json();
          let jwtExpire = new Date();
          jwtExpire.setTime(jwtExpire.getTime() + (json.allTokens.jwt.expireTime * 1000));
          let refreshExpire = new Date();
          refreshExpire.setTime(refreshExpire.getTime() + (json.allTokens.refresh.expireTime * 1000));
          cookies.set('jwt', json.allTokens.jwt.token, {
            expires: jwtExpire
          });
          cookies.set('refresh', json.allTokens.refresh.token, {
            expires: refreshExpire
          });
        })
        .catch((err) => {
          
        })
    }
  }

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    isloggedin();
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <Breadcrumbs navigation={Navigation} title />
        <Outlet />
      </Box>
    </Box>
  );
};

export default ShopifyLayout;
