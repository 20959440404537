import React from 'react';
import Routes from './routes/index'
import ThemeCustomization from './themes/index';

const App = () => {
  return (
    <div suppressHydrationWarning={true}>
      <ThemeCustomization>
        <Routes />
      </ThemeCustomization>
    </div>

  );
}

export default App;