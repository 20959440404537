import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Input } from "@mui/material";
import { debounce, set } from "lodash";
import Button from "@mui/material/Button";
import { updateShopifyCms } from "../shopifyCms/updateShopifyCms";

const AutoScrollDataCard = ({ indx, item, handleChange, len, deleteAutoScrollData }) => {
  const [index, setIndex] = useState(indx);
  const [text, setText] = useState(item?.text);
  const [url, setUrl] = useState(item?.url);
  useEffect(() => {
    setIndex(indx);
  }, [indx]);

  let debounceHandleChange = debounce(
    ({ name, value }) => handleChange({ index, name, value }),
    1000
  );
  const handleAutoScrollChange = (event) => {
    const { name, value } = event.target;
    if (name === "index") {
      setIndex(value);
    } else if (name === "text") {
      setText(value);
    } else if (name === "url") {
      setUrl(value);
    }
    // write debounce function for handleChange
    debounceHandleChange({ name, value });
  };
  const arr = new Array(len).fill(0);
  return (
    <Box key={item?.text} sx={{ p: 2, border: "1px solid", mb: 1.5 }}>
      <InputLabel id="index">Index</InputLabel>
      <Select
        labelId="index"
        id="index"
        value={index}
        label="index"
        onChange={handleAutoScrollChange}
        name="index"
      >
        {arr.map((_, i) => (
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>
        ))}
      </Select>
      <Input
        placeholder="text"
        value={text}
        name="text"
        onChange={handleAutoScrollChange}
        sx={{ mr: 2 }}
      />
      <Input
        placeholder="Url"
        value={url}
        name="url"
        onChange={handleAutoScrollChange}
      />
      <Button 
        variant="contained"
        sx={{ ml: 2 }}
        onClick={() => deleteAutoScrollData(index)}
      >
        Delete
      </Button>
    </Box>
  );
};

const AnnouncementBarCMS = ({ show, data, setIsLoading, setApiRes }) => {
  const [cmsData, setCmsData] = useState({});
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setCmsData(data);
  }, [data]);

  const handleChange = (event) => {
    setIsChanged(true);
    setCmsData({
      ...cmsData,
      data: { ...cmsData.data, [event.target.name]: event.target.value },
    });
  };

  const handleAutoScrollChange = ({ index, name, value }) => {
    setIsChanged(true);
    const data = [...cmsData?.data?.autoScroll?.data];
    if (name === "text" || name === "url") {
      data[index] = { ...data?.[index], [name]: value };
      setCmsData({
        ...cmsData,
        data: {
          ...cmsData?.data,
          autoScroll: { data },
        },
      });
    } else if (name === "index") {
      const item = data?.[index];
      data.splice(index, 1);
      data.splice(value, 0, item);
      setCmsData({
        ...cmsData,
        data: {
          ...cmsData?.data,
          autoScroll: { data },
        },
      });
    }
  };

  const handleColorChangeAutoScroll = (event) => {
    setIsChanged(true);
    const { name, value } = event.target;
    setCmsData({
      ...cmsData,
      data: {
        ...cmsData?.data,
        autoScroll: { ...cmsData?.data?.autoScroll, [name]: value },
      },
    });
  };

  const addNewAutoScrollData = () => {
    setIsChanged(true);
    const data = [...cmsData?.data?.autoScroll?.data];
    data.push({ text: "", url: "" });
    setCmsData({
      ...cmsData,
      data: {
        ...cmsData?.data,
        autoScroll: { data },
      },
    });
  };

  const deleteAutoScrollData = (index) => {
    setIsChanged(true);
    const data = [...cmsData?.data?.autoScroll?.data];
    data.splice(index, 1);
    setCmsData({
      ...cmsData,
      data: {
        ...cmsData?.data,
        autoScroll: { data },
      },
    });
  };

  const handleCountDownChange = (event) => {
    setIsChanged(true);
    const { name, value } = event.target;
    if (name === "endDate") {
      // convert to ISO string in local time
      const date = new Date(value);

      const isoString = date.toISOString();
      setCmsData({
        ...cmsData,
        data: {
          ...cmsData?.data,
          countDown: { ...cmsData?.data?.countDown, [name]: isoString },
        },
      });
    } else {
      setCmsData({
        ...cmsData,
        data: {
          ...cmsData?.data,
          countDown: { ...cmsData?.data?.countDown, [name]: value },
        },
      });
    }
  };

  const getDate = (value) => {
    const date = new Date(value);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const handleCancel = () => {
    setCmsData(data);
    setIsChanged(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await updateShopifyCms("announcementBar", cmsData?.data);
    setApiRes(response);
    setIsLoading(false);
  };

  return (
    <Box sx={{ display: show ? "block" : "none", mx: { xs: 2, md: 6 } }}>
      <Typography variant="h5">Announcement Bar CMS</Typography>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="type">Type</InputLabel>
        <Select
          labelId="type"
          id="type"
          value={cmsData?.data?.type || ""}
          label="Type"
          onChange={handleChange}
          name="type"
        >
          <MenuItem value="countDown">Count Down</MenuItem>
          <MenuItem value="autoScroll">Auto Scroll</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="showAppBar">Show App Bar</InputLabel>
        <Select
          labelId="showAppBar"
          id="showAppBar"
          value={cmsData?.data?.showAppBar || false}
          label="showAppBar"
          onChange={handleChange}
          name="showAppBar"
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ p: 2, border: "1px solid" }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Auto Scroll
        </Typography>

        <Box sx={{ mt: 1 }}>
          <InputLabel id="text-color">Text Color</InputLabel>
          {cmsData?.data?.autoScroll?.textColor && (
            <Input
              labelId="text-color"
              placeholder="text color"
              type="color"
              value={cmsData?.data?.autoScroll?.textColor}
              name="textColor"
              onChange={handleColorChangeAutoScroll}
              sx={{ width: "50px" }}
            />
          )}
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => {
              if (cmsData?.data?.autoScroll?.textColor) {
                handleColorChangeAutoScroll({
                  target: { name: "textColor", value: "" },
                });
              } else {
                handleColorChangeAutoScroll({
                  target: { name: "textColor", value: "#000000" },
                });
              }
            }}
          >
            {cmsData?.data?.autoScroll?.textColor
              ? "Remove Text Color"
              : "Add Text color"}
          </Button>
        </Box>
        <Box sx={{ my: 1 }}>
          <InputLabel id="backgroundColor">Background Color</InputLabel>
          {cmsData?.data?.autoScroll?.backgroundColor && (
            <Input
              labelId="backgroundColor"
              placeholder="backgroundColor"
              type="color"
              value={cmsData?.data?.autoScroll?.backgroundColor}
              name="backgroundColor"
              onChange={handleColorChangeAutoScroll}
              sx={{ width: "50px" }}
            />
          )}
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => {
              if (cmsData?.data?.autoScroll?.backgroundColor) {
                handleColorChangeAutoScroll({
                  target: { name: "backgroundColor", value: "" },
                });
              } else {
                handleColorChangeAutoScroll({
                  target: { name: "backgroundColor", value: "#000000" },
                });
              }
            }}
          >
            {cmsData?.data?.autoScroll?.backgroundColor
              ? "Remove Background Color"
              : "Add Background color"}
          </Button>
        </Box>
        {cmsData?.data?.autoScroll?.data?.map((item, index) => (
          <AutoScrollDataCard
            key={item?.text}
            indx={index}
            item={item}
            handleChange={handleAutoScrollChange}
            len={cmsData?.data?.autoScroll?.data?.length}
            deleteAutoScrollData={deleteAutoScrollData}
          />
        ))}
        <Button variant="contained" sx={{ mt: 1 }} onClick={addNewAutoScrollData}>
          Add New
        </Button>
      </Box>
      <Box sx={{ p: 2, border: "1px solid", my: 2 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Count Down
        </Typography>
        <Box>
          <Input
            placeholder="text"
            value={cmsData?.data?.countDown?.text}
            name="text"
            onChange={handleCountDownChange}
          />
        </Box>
        <Box>
          <InputLabel id="endDate">endDate</InputLabel>
          <Input
            placeholder="endDate"
            type="datetime-local"
            value={getDate(cmsData?.data?.countDown?.endDate)}
            name="endDate"
            onChange={handleCountDownChange}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <InputLabel id="text-color">Text Color</InputLabel>
          {cmsData?.data?.countDown?.textColor && (
            <Input
              labelId="text-color"
              placeholder="text color"
              type="color"
              value={cmsData?.data?.countDown?.textColor}
              name="textColor"
              onChange={handleCountDownChange}
              sx={{ width: "50px" }}
            />
          )}
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => {
              if (cmsData?.data?.countDown?.textColor) {
                handleCountDownChange({
                  target: { name: "textColor", value: "" },
                });
              } else {
                handleCountDownChange({
                  target: { name: "textColor", value: "#000000" },
                });
              }
            }}
          >
            {cmsData?.data?.countDown?.textColor
              ? "Remove Text Color"
              : "Add Text color"}
          </Button>
        </Box>
        <Box sx={{ mt: 1 }}>
          <InputLabel id="backgroundColor">Background Color</InputLabel>
          {cmsData?.data?.countDown?.backgroundColor && (
            <Input
              labelId="backgroundColor"
              placeholder="backgroundColor"
              type="color"
              value={cmsData?.data?.countDown?.backgroundColor}
              name="backgroundColor"
              onChange={handleCountDownChange}
              sx={{ width: "50px" }}
            />
          )}
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => {
              if (cmsData?.data?.countDown?.backgroundColor) {
                handleCountDownChange({
                  target: { name: "backgroundColor", value: "" },
                });
              } else {
                handleCountDownChange({
                  target: { name: "backgroundColor", value: "#000000" },
                });
              }
            }}
          >
            {cmsData?.data?.countDown?.backgroundColor
              ? "Remove Background Color"
              : "Add Background color"}
          </Button>
        </Box>
      </Box>
      <Box>
        <Button variant="contained" sx={{ mt: 1, mb: 2 }} onClick={handleSave} disabled={!isChanged}>
          Save
        </Button>
        <Button variant="contained" sx={{ mt: 1, mb: 2, ml: 2 }} onClick={handleCancel} disabled={!isChanged}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AnnouncementBarCMS;
