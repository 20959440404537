import React from 'react';
// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'catalogue',
  title: 'Catalogue',
  type: 'group',
  children: [
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/cam-manager/products',
    },
    {
      id: 'inventory',
      title: 'Inventory',
      type: 'item',
      url: '/cam-manager/inventory',
    },
  ]
};

export default pages;
