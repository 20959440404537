import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {  featuredCollectionUrl: [],collectionUrl: [], productsUrl: [], blogsUrl: [], pagesUrl: [], blogPostsUrl: [] };
// ==============================|| SLICE - MENU ||============================== //
const shopifyCmsCollection = createSlice({
  name: 'shopifyCmsCollection',
  initialState,
  reducers: {
    setFeaturedCollectionUrl(state, action) {
      state.featuredCollectionUrl = action.payload.featuredCollectionUrl
    },
    setCollectionUrl(state, action) {
      state.collectionUrl = action.payload.collectionUrl
    },
    setProductsUrl(state, action) {
      state.productsUrl = action.payload.productsUrl
    },
    setBlogsUrl(state, action) {
      state.blogsUrl = action.payload.blogsUrl
    },
    setPagesUrl(state, action) {
      state.pagesUrl = action.payload.pagesUrl
    },
    setBlogPostsUrl(state, action) {
      state.blogPostsUrl = action.payload.blogPostsUrl
    },
  }
});

export default shopifyCmsCollection.reducer;
export const { setFeaturedCollectionUrl,setCollectionUrl, setProductsUrl, setBlogsUrl, setPagesUrl ,setBlogPostsUrl} = shopifyCmsCollection.actions;