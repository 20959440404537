import React from 'react';
// project import
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import pages from './pages';
import repositoryTables from './repositoryTables';
import BqDashboardItems from './bqDashboard';
import shopifyCms from './shopifyCms';
import shopifyCollections from './shopifyCollections';
// ==============================|| MENU ITEMS ||============================== //
const menuItemsCamManager = {
  items: [dashboard, utilities, pages],
};

const menuItemsRepository = {
  items: [repositoryTables]
};

const bqDashboardItems = {
  items: [BqDashboardItems]
};
const shopifyCmsItems = {
  items: [shopifyCms]
};
const shopifyCollectionsItems = {
  items: [shopifyCollections]
}

export { menuItemsCamManager, menuItemsRepository, bqDashboardItems, shopifyCmsItems, shopifyCollectionsItems };
