import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const JsonEditor = ({
  id,
  title,
  errorMessage,
  value,
  handleChange,
}) => {
  const stringifyBeatifyJson = (json) => {
    if (typeof json === "object") {
      return JSON.stringify(json, null, 2);
    } else if (typeof json === "string") {
      return json;
    }
    return "";
  };
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {errorMessage ? (
        <Typography
          variant="p"
          sx={{
            px: 2,
            py: 1,
            backgroundColor: errorMessage ? "#ff000030" : null,
            border: errorMessage ? "2px solid red" : null,
            borderRadius: "5px",
          }}
        >
          {errorMessage}
        </Typography>
      ) : null}
      <TextField
        id={id}
        label={title}
        multiline
        rows={20}
        variant="filled"
        value={stringifyBeatifyJson(value)}
        onChange={handleChange}
        sx={{
          width: "100%",
          border: errorMessage ? "2px solid red" : null,
          borderRadius: "10px",
          mt: 2,
        }}
      />
    </Box>
  );
};

export default JsonEditor;
