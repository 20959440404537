import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ArrowButton = ({ direction, onClick }) => {
  return (
    <IconButton
      aria-label="ArrowButton"
      color="black"
      sx={{
        borderRadius: "4px",
        background: "white",
        border: "1px solid",
        borderWidth: "1px",
        borderColor: "#d1d1d1",
        width: "3.5rem",
        height: "3.5rem"
      }}
      onClick={onClick}
    >
      {direction === "right" ? <ArrowForwardIcon sx={{ fontSize: "1.2rem" }} /> : <ArrowBackIcon sx={{ fontSize: "1.2rem" }} />}
    </IconButton>
  );
};

export default ArrowButton;
