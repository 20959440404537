import React from 'react';
// third-party
import { combineReducers } from 'redux';
// project import
import menu from './menu';
import shopifyProductsSlice from './shopifyProductsSlice';
import shopifyCmsCollection from './shopifyCmsCollection';
import shopifyCmsBarList from './shopifyCmsBarList';
// ==============================|| COMBINE REDUCERS ||============================== //
const reducers = combineReducers({ menu, shopifyProductsSlice ,shopifyCmsCollection,shopifyCmsBarList});

export default reducers;