import React from 'react';
import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout/index';

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Home = Loadable(lazy(() => import('../pages/HomeScreen')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '',
      element: <Login />
    },
    {
      path: 'home',
      element: <Home />
    }
  ]
};

export default LoginRoutes;
