"use client";
import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import styles from "./productsRender.module.css";
import ProductItem from "./components/ProductItem";
import ArrowButton from "./components/ArrowButton";

const fetchCollectionProducts = async ({ handle, productCount = 20 }) => {
  const endpoint = `${
    process.env.REACT_APP_FS_SERVER_BASE_URL || "http://localhost:3000"
  }/v0/api/shopifyCms/get-collection-products`;
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ handle, productCount }),
  };
  const result = await fetch(endpoint, requestOptions).then(
    async (response) => {
      const responseRes = JSON.parse(await response?.text());
      return responseRes;
    }
  );
  return result;
};

const fetchProductsByHandles = async ({ handles }) => {
  const endpoint = `${
    process.env.REACT_APP_FS_SERVER_BASE_URL || "http://localhost:3000"
  }/v0/api/shopifyCms/get-products`;
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ handles }),
  };
  const result = await fetch(endpoint, requestOptions).then(
    async (response) => {
      const responseRes = JSON.parse(await response?.text());
      return responseRes;
    }
  );
  return result;
};

const ProductsRender = ({ data }) => {
  const [products, setProducts] = useState([]);
  const [gridLoader, setGridLoader] = useState(true);
  const scrollRef = useRef(null);

  const getCollectionProducts = async ({ handle, productCount }) => {
    try {
      const response = await fetchCollectionProducts({ handle, productCount });
      if (response?.data?.collection?.products?.nodes) {
        setProducts(response?.data?.collection?.products?.nodes);
      }
      setGridLoader(false);
    } catch (error) {
      setGridLoader(false);
    }
  };

  const getProductsByHandles = async ({ handles }) => {
    try {
      const response = await fetchProductsByHandles({ handles });
      if (response?.data?.products) {
        setProducts(Object.values(response?.data?.products));
      }
      setGridLoader(false);
    } catch (error) {
      setGridLoader(false);
    }
  };

  useEffect(() => {
    if (data?.type === "collection") {
      getCollectionProducts({
        handle: data?.handle,
        productCount: data?.productCount || 20,
      });
    } else if (data?.type === "product") {
      getProductsByHandles({ handles: data?.products });
    }
  }, [data]);

  const scrollRight = () =>
    (scrollRef.current.scrollLeft += window.innerWidth - 28);
  const scrollLeft = () =>
    (scrollRef.current.scrollLeft -= window.innerWidth - 28);

  if (products?.length === 0) return null;

  return (
    <Box className={styles.container} sx={{ my: 3 }}>
      <Box sx={{ width: "100%", position: "relative", mt: 1 }}>
        <Box className={styles.arrowBtnContainer} sx={{ left: "10px" }}>
          <ArrowButton direction="left" onClick={scrollLeft} />
        </Box>
        <Box className={styles.arrowBtnContainer} sx={{ right: "10px" }}>
          <ArrowButton direction="right" onClick={scrollRight} />
        </Box>
        <Box
          sx={{
            overflowX: "auto",
            width: "100%",
            maxWidth: "1600px",
          }}
        >
          <Box ref={scrollRef} className={styles.scrollContainer}>
            {products?.map((item, index) => (
              <Box
                key={item?.id || index}
                className={styles.productItemContainer}
              >
                <ProductItem data={item} nextImage={true} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductsRender;
