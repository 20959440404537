import React from 'react';
// import { useTheme } from '@mui/material/styles';

const Logo = () => {
  // const theme = useTheme();
  const logoColor = '#5b5663';
  return (
    <>
      <svg width="35" height="35" viewBox="0 0 35 35" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={logoColor} strokeWidth='1.41465'
          transform="matrix(.19001 0 0 .19001 .712 -48)"
          d="m88.35 274.44-15.52-12.68h31.04zM88.35 415.02l15.52 12.67H72.83zM98.3 316.84l.03-.52.06.79z"
        />
        <path
          d="m34.504-10.536-.07 1.132.183.58z"
          fill={logoColor} strokeWidth='1.41465'
          transform="matrix(.13432 0 0 .13432 14.245 15.391)"
        />
        <path
          d="M103.51 34.11C97.995 48.64 93.142 68.7 68.23 68.7c-17.4 0-25.238-9.69-25.238-20.88 0-31.094 60.18-19.735 60.18-57.18 0-8.305-3.184-16.382-9.748-21.659-7.88-6.323-18.829-7.44-28.59-7.455h-89.378c-5.8.014-11.6.014-17.4.014H-56.047v1.938c14.94 0 16.51 4.173 16.51 15.618v73.604c0 11.473-1.57 15.632-16.51 15.632v1.216h51.24v-1.216c-14.94 0-17.373-3.99-17.373-15.971V17.984h3.127c23.087 0 30.895 6.422 33.838 23.978h1.06V-7.353h-1.06C11.827 10.02 4.033 16.625-19.054 16.625h-3.127v-52.964h12.322c30.91 0 39.412 12.124 43.939 32.424h.863c4.682-11.713 11.034-32.382 32.933-32.382 14.896 0 22.238 9.196 22.238 19.55 0 29.581-60.363 18.886-60.363 57.337 0 16.72 11.53 29.24 39.299 29.24 12.364 0 24.742-2.503 33.598-4.512l1.853-31.094-.99-.156zM34.434-9.488l.071-1.132.113 1.712z"
          fill={logoColor} strokeWidth='1.41465'
          transform="matrix(.13432 0 0 .13432 14.245 15.391)"
        />
        <path
          d="M24.234 140.69c-68.922 0-124.984-56.076-124.984-124.998s56.062-124.984 124.984-124.984c68.921 0 124.984 56.076 124.984 124.984 0 68.907-56.063 124.984-124.984 124.984zm0-242.188c-64.621 0-117.204 52.583-117.204 117.204S-40.387 132.91 24.234 132.91c64.62 0 117.203-52.583 117.203-117.204S88.855-101.498 24.234-101.498z"
          fill={logoColor} strokeWidth='1.41465'
          transform="matrix(.13432 0 0 .13432 14.245 15.391)"
        />
      </svg>

    </>
  );
};

export default Logo;